/* https://github.com/Cognigy/WebchatWidget/blob/master/docs/css-customization.md */

[data-cognigy-webchat-root].webchat-root {
  /* Hide by default */
  display: none;
}

[data-cognigy-webchat-root] [data-cognigy-webchat].webchat .webchat-avatar.bot {
  background-image: url("../img/UiPath_Robot.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none !important;
  background-color: transparent;
  border: none;
}

[data-cognigy-webchat-root] [data-cognigy-webchat-toggle].webchat-toggle-button {
  height: 64px;
  width: 64px;
  background-image: url("../img/UiPath_Robot.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none !important;
  background-color: transparent;
}

[data-cognigy-webchat-root] [data-cognigy-webchat-toggle].webchat-toggle-button:hover {
  height: 64px;
  width: 64px;
  background-image: url("../img/UiPath_Robot.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none !important;
  background-color: transparent;
}

[data-cognigy-webchat-root] [data-cognigy-webchat-toggle].webchat-toggle-button > svg {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
[data-cognigy-webchat-root] [data-cognigy-webchat].webchat .webchat-chat-history::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
[data-cognigy-webchat-root] [data-cognigy-webchat].webchat .webchat-chat-history {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Make control arrows in carousel bigger */
[data-cognigy-webchat-root] .carousel.carousel-slider .control-arrow {
  padding: 10px !important;
}
