@import url('https://fonts.googleapis.com/css?family=Inter:400,500,600,700,800,900&display=swap');


/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
  font-size: 62.5%;
  line-height: 1.15; /* 1 */
  position: relative;
  min-height: 100%;
  -webkit-text-size-adjust: 100%; /* 2 */
}

:root {
  --background-color: #ffffff;
  --background-color-raised: #ffffff;
  --text-primary-color: #263038;
}

/* Dark theme overrides */
.dark-theme {
  --background-color: #182027;
  --background-color-raised: #263138;
  --text-primary-color: #f1f6f9;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  height: 100%;

  /* Remove bouncy scroll */
  overscroll-behavior: none;
}

/* Make app shell fixed */
.app-shell {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Make top-level content scrollable */
.app-content-root {
  overflow: auto;
  height: 100%;
}

.MuiDateCalendar-root {
  background-color: var(--background-color-raised);
}

.MuiPickersCalendarHeader-label {
  color: var(--text-primary-color);
}

.MuiDateCalendar-root {
  background-color: var(--background-color-raised);
}

.MuiPickersCalendarHeader-label {
  color: var(--text-primary-color);
}

:not([class*=hydrated]):focus,
:not([class*=hydrated]):focus-visible {
  outline-color: unset;
  outline-style: unset;
}

#root {
  height: 100%;
}
img {
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.filterButton {
  border-radius: 8px;
  border: 1px solid rgb(247, 247, 247);
  height: 56px;
  width: 282px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
input,
textarea,
body {
  font-family: 'Inter', sans-serif !important;
}

.material-icons {
  font-family: Material Icons !important;
}

h1 {
  font-size: 5.6rem;
  line-height: 1.15;
}

h2 {
  font-size: 4.4rem;
  line-height: 1.1;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.5;
}

h4 {
  font-size: 2rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.8rem;
  line-height: 1.35;
}

h6 {
  font-size: 1.6rem;
  line-height: 1.25;
}

p {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
}

p.Small {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.35;
}

.Bold {
  font-weight: 700;
}

.Regular {
  font-weight: normal;
}

a {
  text-decoration: none;
}

.Bold-Blue-Link {
  color: #0067df;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
}

.Uppercase-Bold-Blue-Link {
  color: #0067df;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
  cursor: pointer;
  text-transform: uppercase;
}

.recharts-legend-item-text {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
  color: #3a464f;
}

.MuiContainer-root {
  max-width: 1280px;
}

input.MuiInputBase-input {
  padding: 10px 8px;
  font-size: 14px;
  border-radius: 4px;
}

.MuiOutlinedInput-notchedOutline {
  top: -5.5px !important;
}

/* <p> tags */
.MuiPopover-paper > p {
  color: #182027;
  &:hover {
    color: #0067df;
  }
}

/* Switches */
.MuiSwitch-switchBase.Mui-disabled > span.MuiSwitch-thumb {
  background-color: #bdbdbd;
}

span.MuiSwitch-track {
  background-color: #424242;
}
span.MuiSwitch-thumb {
  background-color: #ffffff;
}
.Mui-checked > .MuiSwitch-thumb {
  background-color: #0067df;
}

/* Checkboxes */
.MuiFormControlLabel-root > span.MuiTypography-root.MuiTypography-body1 {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.MuiPickersCalendarHeader-label, .MuiDayCalendar-weekDayLabel, .MuiPickersDay-root  {
  font-size: 12px !important;
}

.MuiAccordion-root:before {
  background-color: #e0e0e0 !important;
}

/* .MuiInputLabel-root + .MuiInputBase-root > .MuiOutlinedInput-notchedOutline {
  top: -5px !important;
} */

/* Sections
   ========================================================================== */

/**
 * Render the `main` element consistently in IE.
 */

main {
  display: block;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

li b {
  font-weight: bold !important;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button {
  font-size: 14px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: unset !important;
  height: auto;
}

input {
  font-size: 14px !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
  padding: 6px 8px !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}
